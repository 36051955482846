import { createStore } from 'vuex'

export default createStore({
  state: {
    // baseUrlLocal:"http://localhost:8080/",
    // baseUrl: "https://registration.manialab.sa",
    // baseFieldImageUrl:'https://registration.manialab.sa/storage/app/public/field-image/',
    // baseBadgeImageUrl:'https://registration.manialab.sa/storage/app/public/badge-image/',
    // baseBadgeBGImageUrl:'https://registration.manialab.sa/storage/app/public/badge-bg-image/',
    // baseHeaderTypeImageUrl:'https://registration.manialab.sa/storage/app/public/header-image/',
    // baseFooterTypeImageUrl:'https://registration.manialab.sa/storage/app/public/footer-image/',
    // baseIconImageUrl:'https://registration.manialab.sa/storage/app/public/icon/',
    // baseLogoImageUrl:'https://registration.manialab.sa/storage/app/public/logo/',
    // baseLogoFontUrl:'https://registration.manialab.sa/storage/app/public/font-image/',
    // baseDocumentUrl:'https://registration.manialab.sa/storage/app/public/document/',
    // baseApiURL: "https://registration.manialab.sa/api/",
    // baseBadgeURL: "https://registration.manialab.sa/badge?t=",
    // baseRegistrationURL:"https://registration.manialab.sa/register?id=",
    roles:[],
    role_id:null,



    
    // baseUrl: "https://registration2.manialab.sa",
    // baseFieldImageUrl:'https://registration2.manialab.sa/storage/app/public/field-image/',
    // baseBadgeImageUrl:'https://registration2.manialab.sa/storage/app/public/badge-image/',
    // baseBadgeBGImageUrl:'https://registration2.manialab.sa/storage/app/public/badge-bg-image/',
    // baseHeaderTypeImageUrl:'https://registration2.manialab.sa/storage/app/public/header-image/',
    // baseFooterTypeImageUrl:'https://registration2.manialab.sa/storage/app/public/footer-image/',
    // baseIconImageUrl:'https://registration2.manialab.sa/storage/app/public/icon/',
    // baseLogoImageUrl:'https://registration2.manialab.sa/storage/app/public/logo/',
    // baseLogoFontUrl:'https://registration2.manialab.sa/storage/app/public/font-image/',
    // baseDocumentUrl:'https://registration2.manialab.sa/storage/app/public/document/',
    // baseApiURL: "https://registration2.manialab.sa/api/",
    // baseBadgeURL: "https://registration2.manialab.sa/badge?t=",
    // baseRegistrationURL:"https://registration2.manialab.sa/register?id=",



    baseUrl: "https://demoregistration.manialab.sa",
    baseFieldImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/field-image/',
    baseBadgeImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/badge-image/',
    baseBadgeBGImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/badge-bg-image/',
    baseHeaderTypeImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/header-image/',
    baseFooterTypeImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/footer-image/',
    baseIconImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/icon/',
    baseLogoImageUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/logo/',
    baseLogoFontUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/font-image/',
    baseDocumentUrl:'https://demoregistration.manialab.sa/demoreg/storage/app/public/document/',
    baseApiURL: "https://demoregistration.manialab.sa/api/",
    baseBadgeURL: "https://demoregistration.manialab.sa/badge?t=",
    baseRegistrationURL:"https://demoregistration.manialab.sa/register?id=",

    loader:0,
    LayoutLoader:0,
    pageLoader:0,
    upadteType:false,
    allowedImages:['jpg', 'jpeg', 'png'],
    allowedFonts:['ttf', 'otf', 'woff', 'woff2'],

  },
  getters: {
    hasRole: (state) => (role) => {
      // return true;
      return state.roles.includes(role);
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
