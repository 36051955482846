<script setup>

</script>

<template>
<router-view/>
</template>

<style scoped>

</style>